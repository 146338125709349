<template>
  <div v-if="loaded" class="container">
    <div class="row">
      <div class="col-12">
        <h1 class="page-title text-center">Meine Bestellungen</h1>
      </div>
      <div class="row">
        <div class="col-3">
          <Menu />
        </div>
        <div class="col-9">
          <div v-if="orders.length === 0" class="">
              <p>Keine Bestellungen gefunden</p>
          </div>
          <OrderSummary
            v-else
            v-for="order in orders"
            v-bind:key="order.id"
            v-bind:order="order"
          />
        </div>
      </div>
    </div>
  </div>
  <div v-else>
    <Spinner/>
  </div>
</template>

<script>
import axios from "axios";
import Spinner from "@/components/Spinner.vue";
import Menu from "@/components/AccountMenu.vue";
import OrderSummary from "@/components/OrderSummary.vue";
export default {
  name: "Order",
  components: {
    Spinner,
    Menu,
    OrderSummary,
  },
  data() {
    return {
      loaded: false,
      orders: [],
    };
  },
  mounted() {
    document.title = "Bestellungen - scanner4you";
    this.getMyOrders();
  },
  methods: {
    logout() {
      axios.defaults.headers.common["Authorization"] = "";
      localStorage.removeItem("token");
      localStorage.removeItem("username");
      localStorage.removeItem("userid");
      this.$store.commit("removeToken");
      this.$router.push("/");
    },
    async getMyOrders() {
      await axios
        .get("/api/v1/orders/")
        .then((response) => {
          this.orders = response.data;
          this.loaded = true
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
};
</script>

<style>
.account-menu-item {
  cursor: pointer;
}
</style>